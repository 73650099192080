<template>
  <div v-if="!isLoading">
    <div v-if="!isSignedIn" class="container flex justify-center items-center h-screen">
      <SigninForm @submit="submit"/>
    </div>

    <div class="container py-4" v-else>
      <img src="/img/png/Logo.png" class="h-[60px] block mx-auto" alt="Logo">
      <h1 class="text-left font-bold text-primary text-xl">Admin panel</h1>

      <el-tabs tab-position="left" >
        <el-tab-pane label="Create survey">
          <form class="flex flex-col items-start  w-fit" @submit.prevent="generatePin">
            <label for="pin">Select survey</label>
            <el-select required id="pin" v-model="pinForm.survey">
              <el-option v-for="s in survey" :key="s" :label="s" :value="s">{{s}}</el-option>
            </el-select>

            <label for="school">Select School</label>
            <el-select required id="pin" v-model="pinForm.createdBy">
              <el-option v-for="s in schools" :key="s.id" :label="s.name" :value="s.id">{{s.name}}</el-option>
            </el-select>

            <span v-if="pinForm.pin">https://truwell.com/survey/{{pinForm.survey.toLowerCase().replaceAll(" ", "_")}}?pin={{pinForm.pin}}</span>
            <button :disabled="!pinForm.survey || !pinForm.createdBy" class="disabled:bg-gray-600 bg-primary px-3 py-2 text-white rounded-lg mt-2" type="submit">Create</button>
          </form>

        </el-tab-pane>
        <el-tab-pane label="Create school">
          <h2 class="text-lg font-semibold">Create school</h2>
          <form class="flex flex-col gap-2 items-start" @submit.prevent="createSchool">
            <el-input v-model="schoolForm.name" placeholder="Name"/>
            <button class="bg-primary px-3 py-2 text-white rounded-lg" type="submit">Create</button>
          </form>

          <div class="grid grid-cols-3 gap-2 mt-4">
            <div class="bg-stone-50 flex flex-col gap-1 items-start p-3 border shadow rounded-md" v-for="s in schools" :key="s.id">
              <span>{{s.name}}</span>
              <span>{{s.email}}</span>
              <div class="flex flex-col p-2 border" v-for="survey in s.Survey" :key="survey.gamePin">
                {{survey.gamePin}}
                <a class="underline text-primary font-semibold" :href="`https://truwell.com/analytics/${survey.gameName.toLowerCase().replaceAll(' ', '_')}?pin=${survey.gamePin}`">Analytics</a>
                <a class="underline text-primary font-semibold" :href="`https://truwell.com/survey/${survey.gameName.toLowerCase().replaceAll(' ', '_')}?pin=${survey.gamePin}&school_name=${s.name}`">Survey page</a>
              </div>
              <el-button round type="danger" @click="removeSchool(s.id)">
                <i class="fa-solid fa-trash"></i>
              </el-button>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Create user">
          <h2 class="text-lg font-semibold">Create user</h2>
          <form class="flex flex-col gap-2 items-start" @submit.prevent="createUser">
            <el-input v-model="userForm.login" placeholder="Login"/>
            <el-input v-model="userForm.name" placeholder="Name"/>
            <el-input v-model="userForm.password" placeholder="Password"/>
            <el-select multiple v-model="userForm.school_id">
              <el-option v-for="s in schools" :key="s.id" :label="s.name" :value="s.id">{{s.name}}</el-option>
            </el-select>
            <button class="bg-primary px-3 py-2 text-white rounded-lg" type="submit">Create</button>
          </form>

          <div class="grid grid-cols-3 gap-2 mt-4">
            <div
                class="bg-stone-50 flex flex-col gap-1 items-start p-3 border shadow rounded-md"
                v-for="u in users" :key="u.id">
              <span>Name: {{u.name}}</span>
              <span>Login: {{u.email}}</span>
              <span>{{u.UserOnSchools?.map(v => v.school.name).join(', ')}}</span>
<!--              <span>School: {{u.School.name}}</span>-->
              <el-select @change="handleSchoolChange(u)" multiple v-model="u.school_id">
                <el-option v-for="s in schools" :key="s.id" :label="s.name" :value="s.id">{{s.name}}</el-option>
              </el-select>
              <el-button round type="danger" @click="removeUser(u.id)">
                <i class="fa-solid fa-trash"></i>
              </el-button>
            </div>
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>
  </div>

</template>

<script>
import {UserStatusMixin} from "@/mixins/userStatusMixin";
import SigninForm from "@/components/Shared/signin-form.vue";

export default {
  components: {SigninForm},
  data: () => ({
    form: {
      login: "",
      password: "",
    },
    pinForm: {survey: ""},
    schools: [],
    survey: ["Staff wellbeing survey", "Permah audit"],
    pin: "",
    userForm: {},
    schoolForm: {},
    users: [],
  }),
  mixins: [
    UserStatusMixin
  ],
  async mounted() {
    await this.checkStatus()
    if(this.isSignedIn) {
      const isAdmin = await this.isAdmin()

      if(!isAdmin) {
        return this.$router.push("/")
      }
      await this.getSchools()
      await this.getSchoolUsers()
    }
  },
  methods: {
    async handleSchoolChange(u) {
      const res =await this.$root.schoolApi.changeSchool(u.id, u.school_id)
      this.$message.success("Successfully updated user")
    },
    async removeSchool(id) {
      this.schools = this.schools.filter(s => s.id !== id)
      await this.$root.schoolApi.remove(id)
    },
    async removeUser(id) {
      this.users = this.users.filter(s => s.id !== id)
      await this.$root.api.removeUser(id)
    },
    async createSchool() {
      const res = await this.$root.schoolApi.create(this.schoolForm)

      await this.getSchools()
    },
     async createUser() {
      const res = await this.$root.api.register({...this.userForm, email: this.userForm.login, school_id: this.userForm.school_id, role: "school"})

      await this.getSchoolUsers()
    },
    async getSchools() {
      const res = await this.$root.schoolApi.findAllSchools()
      this.schools = res
    },
    async getSchoolUsers() {
      const res = await this.$root.api.getSchools()
      this.users = res
    },
    async generatePin () {
      const res = await this.$root.api.generatePin({gameName: this.pinForm.survey, createdBy: this.pinForm.createdBy})

      this.pinForm.pin = res.gamePin
    }
  }
}
</script>

<style lang="scss" scoped>

</style>